<template>
  <div class="tw-w-full">
    <v-dialog v-model="dialog" persistent width="500">
         <div class="modal tw-p-10">
            <h6 class="modal-header">Withdraw from Wallet</h6>

           <div class="tw-flex tw-flex-row tw-items-center tw-py-6">
             <p class="normal-text">Wallet balance :</p>
             <p class="bold-text tw-pl-3" v-if="walletBalance">₦{{walletBalance.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
           </div>
           <v-divider  class="tw-mt-2 tw-mb-4"/>

           <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-2">
             <p style="font-size: 14px !important;" class="normal-text">Amount to Withdraw</p>
             <p style="font-size: 14px !important;" @click="handleMaxAmount"
                :style="{color : companyData.companyColor ? companyData.companyColor : ''}"
                class="bold-text tw-cursor-pointer">Max Amount</p>
           </div>
           <div class="tw-w-full">
             <v-text-field solo hide-details placeholder="20000" v-model="withdrawalAmount">

             </v-text-field>
           </div>
           <v-divider  class="tw-mt-12 tw-mb-2"/>
           <div class="tw-w-full tw-items-center tw-justify-between tw-flex-row tw-flex tw-py-3">
             <p style="font-size: 14px !important;" class="bold-text">Payment Account</p>
<!--             <div class="tw-flex tw-items-center tw-flex-row">-->
<!--               <p class="update tw-pr-1">update</p>-->
<!--               <img src="@/assets/carbon_help.svg" alt="help" />-->
<!--             </div>-->
           </div>

           <p style="font-size: 14px !important;" class="normal-text tw-pt-4">Bank Name</p>

           <p style="font-size: 14px !important;" class="bold-text tw-py-2">{{companyData.accountDetail.bankName}}</p>

           <p style="font-size: 14px !important;" class="normal-text tw-pt-4">Account Name</p>

           <p style="font-size: 14px !important;" class="bold-text tw-py-2">{{companyData.accountDetail.accountName}}</p>

           <p style="font-size: 14px !important;" class="normal-text tw-pt-4">Account Number</p>

           <p style="font-size: 14px !important;" class="bold-text tw-py-2">{{companyData.accountDetail.accountNumber}}</p>

           <div class="tw-w-full tw-flex tw-items-center tw-justify-end mt-7">
             <v-btn dark :color="color" depressed class="btn-no mr-4 btn-generic-style" @click="$emit('closeModal')">Cancel</v-btn>
             <v-btn :disabled="withdrawalAmount <= 0" :style="{color: color,
                backgroundColor: 'var(--white)',
                border :withdrawalAmount <= 0 ? '' : '1px solid'+color}" depressed class="btn-generic-style"
                    @click="$emit('action',withdrawalAmount)" :loading="loading">Withdraw</v-btn>

           </div>
         </div>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "WithdrawalModal",
  props:{
     dialog :{
       type : [Boolean],
       default: false
     },
    color:{
       type : [String],
       default: "#004AAD"
    },
    walletBalance: [Number],
    loading : {
       type: [Boolean],
       default : false
    }
  },
  data(){
    return{
      withdrawalAmount : ""
    }
  },
  computed:{
    ...mapGetters("companyProfile",["companyData"])
  },
  methods:{
    handleMaxAmount(){
      this.withdrawalAmount = this.walletBalance
    }
  }
}
</script>

<style scoped lang="scss">
.modal{
  display: flex;
  flex-direction: column;
  width: 493px;
  min-height: 758px;
  height: max-content;
  background: #FDFFFC;
  border: 1px solid rgba(6, 28, 58, 0.06);
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}

::v-deep .v-dialog {
  box-shadow: none !important;
  display: flex;
  justify-content: center !important;

}

.modal-header{
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1D262D;
}

.normal-text{
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 19px;
  color: rgba(0, 17, 39, 0.73);
}

.bold-text{
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px;
  color: rgba(0, 17, 39, 0.73);
}

.update{
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 15px;
  color: rgba(0, 17, 39, 0.58);

}

.btn-generic-style {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  font-family: var(--fontInter);
  font-size: 14px;
}
.restrict-btn {
  color: var(--primaryColor);
  background-color: var(--white);
  border: 1px solid var(--primaryColor);
}
</style>